<template>
  <div
    :class="[{ 'slide-left': isVisible }, 'contact-buttons d-flex dir-column']"
  >
    <a
      href="https://wa.me/+201025247890"
      target="_blank"
      class="whatsapp d-flex justify-center align-center"
      ><font-awesome-icon :icon="['fab', 'whatsapp']"
    /></a>
    <a
      href="mailto:admin@emails.miopiazza.com?subject=Inquiry%20about%20services"
      class="email d-flex justify-center align-center"
      ><font-awesome-icon :icon="['far', 'envelope']"
    /></a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    handleScroll() {
      this.isVisible = scrollY > 300;
    },
    checkVisibility() {
      // Show the buttons only when scrolled more than 300px
      this.isVisible = window.scrollY > 300;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
@use "../../sass/utils/mixins" as *;
.contact-buttons {
  z-index: 999;
  position: fixed;
  bottom: 1em;
  right: 0.5em;
  gap: 0.5em;
  transform: translateX(150%);
  transition: transform 0.5s ease;
  @include respond-to("medium") {
    bottom: 1em;
    right: 1em;
  }
  a {
    position: relative;
    width: 40px;
    height: 40px;
    font-size: 1.5em;
    border-radius: 50%;
    color: var(--background);
    transition: background-color 0.5s ease;
    @include respond-to("medium") {
      width: 60px;
      height: 60px;
      font-size: 2em;
    }
    @include hover-device {
      &:hover {
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 0.4em !important;
          color: var(--background);
          padding: 0.4em 0.8em;
          box-shadow: 0 0 8px var(--secondary-text);
          border-radius: 5px;
        }
        &::after {
          content: "";
          position: absolute;
          border: 10px solid transparent;
          top: 50%;
          transform: translateY(-50%);
          left: -10px;
        }
        &.whatsapp::before {
          content: "+201025247890";
          background-color: #25d366;
          left: -123px;
        }
        &.whatsapp::after {
          border-left-color: #25d366;
        }
        &.email::before {
          content: "islamtaha1997@gmail.com";
          left: -185px;
          background-color: #4285f4;
        }
        &.email::after {
          border-left-color: #4285f4;
        }
      }
    }
    &.whatsapp {
      background-color: #25d366;
      @include hover-device {
        &:hover {
          background-color: darken(#25d366, 5);
        }
      }
    }
    &.email {
      background-color: #4285f4;
      @include hover-device {
        &:hover {
          background-color: darken(#4285f4, 5);
        }
      }
    }
  }
}
.slide-left {
  transform: translateX(0) !important;
}
</style>
