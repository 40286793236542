export default {
  namespaced: true,
  state: {
    selectedTab: "quran",
    tabs: ["quran", "islamic studies", "arabic"], // Tab categories
    courses: {},
    selectedCourse: JSON.parse(localStorage.getItem("selectedCourse")) || {},
  },
  mutations: {
    setSelectedTab(state, tab) {
      state.selectedTab = tab;
    },
    setCourses(state, courses) {
      state.courses = courses;
    },
    setSelectedCourse(state, course) {
      state.selectedCourse = course;
    },
  },
  actions: {
    async loadCourses({ commit }) {
      try {
        const response = await fetch("/data/courses.json");
        const courses = await response.json();
        commit("setCourses", courses);
      } catch (error) {
        console.log("Failed to Fetch data", error);
      }
    },
    selectCourse({ commit, state }, courseId) {
      // Flatten the courses object to search all categories (extracts all the nested arrays in one array)
      const allCourses = Object.values(state.courses).flat();

      // Get the selected courese form localStorage if there's any
      const savedSelectedCourse = JSON.parse(
        localStorage.getItem("selectedCourse")
      );

      // Find the course by ID
      const course = allCourses.find((c) => c.id === courseId);
      if (course) {
        if (course !== savedSelectedCourse) {
          commit("setSelectedCourse", course);
          localStorage.setItem("selectedCourse", JSON.stringify(course));
        } else {
          return;
        }
      } else {
        console.error(`Course with ID ${courseId} not found.`);
      }
    },
  },

  getters: {
    filteredCourses: (state) => state.courses[state.selectedTab],
    selectedCourse: (state) => state.selectedCourse,
    allCourses: (state) => state.courses,
  },
};
