import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/plans",
    name: "plans",
    component: () => import("../views/PlansView.vue"),
  },
  {
    path: "/contact-us",
    name: "contact_us",
    component: () => import("../views/ContactUsView.vue"),
  },
  {
    path: "/courses/:id",
    name: "course_details",
    component: () => import("../views/CourseDetails.vue"),
    props: true,
  },
  {
    path: "/terms_and_conditions",
    name: "terms_and_conditions",
    component: () => import("../views/TermsAndConditions.vue"),
    props: true,
  },
  {
    path: "/privacy_policy",
    name: "privacy_policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Show loading screen before navigating
router.beforeEach((to, from, next) => {
  store.dispatch("showLoading");
  next();
});

// Hide Loading screen after navigation is complete
router.beforeEach((to, from, next) => {
  // Show the loading page only if navigating to a new route and the page isn't fully loaded
  if (to.path !== from.path) {
    store.dispatch("showLoading");
  }
  next();
});

router.afterEach((to) => {
  // Wait for the page to be fully rendered before hiding the loading page
  const checkPageLoaded = () => {
    if (document.readyState === "complete") {
      store.dispatch("hideLoading");
    } else {
      // Retry after a short delay if the page isn't ready yet
      setTimeout(checkPageLoaded, 100);
    }
  };

  // Start the check
  checkPageLoaded();

  // Handle smooth scrolling for hash navigation
  if (to.hash) {
    setTimeout(() => {
      const element = document.querySelector(to.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 600); // Adjust the delay for smooth scrolling if needed
  }
});
export default router;
