<template>
  <header :class="{ 'hide-header': isHidden, 'show-header': !isHidden }">
    <div
      class="container d-flex justify-between align-center dir-column t-capitalize"
    >
      <div class="logo">
        <router-link
          to="#landing"
          class="d-flex justify-between align-center t-uppercase"
        >
          <div class="image-holder">
            <img src="/images/logo.jpg" alt="logo" />
          </div>
          Al-Furqan
        </router-link>
      </div>
      <nav class="navigation">
        <router-link to="/">home</router-link>
        <router-link to="/about">about</router-link>
        <div
          :class="[{ active: isDropdown }, 'courses-links']"
          @click="isDropdown = true"
          v-click-outside="closeDropdown"
        >
          courses
          <ul
            :class="[{ visible: isDropdown }, 'dropdown-menu']"
            v-if="isDropdown"
          >
            <span
              @click.stop="closeDropdown"
              class="close-button d-flex justify-center align-center"
              >x</span
            >
            <div
              class="course-category"
              v-for="(courses, category) in allCourses"
              :key="category"
            >
              <h4>{{ category }}</h4>
              <li
                v-for="course in courses"
                :key="course.id"
                @click.stop="closeDropdown"
              >
                <router-link
                  @click="this.selectCourse(course.id)"
                  to="/courses/{{ course.id }}"
                  >{{ course.title }}</router-link
                >
              </li>
            </div>
          </ul>
        </div>
        <router-link to="/plans">plans</router-link>
        <router-link to="/contact-us">contact us</router-link>
      </nav>
    </div>
  </header>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import throttle from "lodash/throttle";

export default {
  name: "HeaderComponent",
  data() {
    return {
      isDropdown: false,
      isHidden: false,
      lastScrollY: 0,
    };
  },
  computed: {
    ...mapGetters("courses", ["allCourses"]),
  },
  methods: {
    ...mapActions("courses", ["loadCourses", "selectCourse"]),
    closeDropdown() {
      this.isDropdown = false;
    },
    handleScroll() {
      const currentScrollY = window.scrollY;

      if (currentScrollY > this.lastScrollY && currentScrollY > 100) {
        // Scrolling down and past 100px
        this.isHidden = true;
      } else {
        // Scrolling up
        this.isHidden = false;
      }

      this.lastScrollY = currentScrollY;
    },
  },
  mounted() {
    // Throttle scroll event handler
    this.throttledScroll = throttle(this.handleScroll, 200);
    window.addEventListener("scroll", this.throttledScroll);
  },
  beforeUnmount() {
    // Clean up event listener
    window.removeEventListener("scroll", this.throttledScroll);
  },
};
</script>
<style lang="scss" scoped>
@use "../sass/utils/mixins" as *;
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
header {
  position: fixed;
  width: 100%;
  height: auto;
  @include respond-to("medium") {
    height: 58px;
  }
  font-family: "Edu AU VIC WA NT Pre", cursive;
  background-color: var(--background);
  border-bottom: 1px solid var(--link);
  box-shadow: 0 0 10px var(--primary);
  z-index: 999;
  .container {
    height: 100%;
    position: relative;
    font-size: 0.8em;
    @include respond-to("medium") {
      font-size: 1em;
      flex-direction: row;
    }
    .logo {
      a {
        height: 100%;
        color: #31b553;
        font-size: 1.2em;
        font-weight: 500;
        .image-holder {
          max-width: 60px;
          img {
            max-width: 100%;
          }
        }
      }
    }
    nav {
      height: 100%;
      @include flex-center;
      > a:not(a.courses-links) {
        height: 100%;
        padding: 0.5em 0.8em;
        border-bottom: 1px solid transparent;
        font-weight: 500;
        @include flex-center;
        &.router-link-exact-active {
          color: var(--hover-link);
          border-color: var(--hover-link);
        }
        @include hover-device {
          &:hover {
            border-color: var(--hover-link);
          }
        }
      }
      // Courses Dropdown Menu Start
      .courses-links {
        height: 100%;
        padding: 0.5em 0.8em;
        border-bottom: 1px solid transparent;
        font-weight: 500;
        transition: color 0.3s;
        color: var(--link);
        cursor: pointer;
        &:hover {
          color: var(--hover-link);
          border-color: var(--hover-link);
        }
        &.active {
          color: var(--hover-link);
          border-color: var(--hover-link);
        }
        @include flex-center;
        .dropdown-menu {
          .close-button {
            position: absolute;
            top: 0.5em;
            right: 0.5em;
            width: 35px;
            height: 35px;
            background-color: var(--primary);
            color: var(--background);
            font-size: 1.1em;
            font-weight: bold;
            border-radius: 50%;
            &:hover {
              background-color: var(--accent);
            }
          }
          position: absolute;
          width: 100%;
          padding: 1em;
          left: 0;
          top: 100%;
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
          gap: 2em;
          background-color: var(--surface);
          border-bottom: 4px solid var(--primary);
          transition: transform 0.5s;
          display: none;
          opacity: 0;
          > div {
            a {
              width: 100%;
              padding: 1em 0.5em;
              display: block;
              font-family: Avenir, Helvetica, Arial, sans-serif;
              border-bottom: 1px solid var(--link);
              transition: background-color 0.3s, border-color 0.3s, color 0.3s;
              @include hover-device {
                &:hover {
                  background-color: var(--surface);
                  border-color: var(--hover-link);
                }
              }
            }
            h4 {
              color: var(--button);
              padding: 1em 0.5em;
              margin-bottom: -1.5em;
            }
          }
        }
      }
      // Courses Dropdown Menu End
    }
  }
}

// Header visibility on scrolling
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
}

header.hide-header {
  transform: translateY(-100%);
}

header.show-header {
  transform: translateY(0);
}

// Header visibility on scrolling end

// Courses visibility
.visible {
  animation: slide-down 0.3s both;
  display: grid !important;
}

@keyframes slide-down {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
