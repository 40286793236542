<template>
  <div class="loading-container">
    <div class="spinner">
      <div class="spinner-inner"></div>
    </div>
    <h1>Loading Page...</h1>
    <p>Just a moment, we're almost ready!</p>
  </div>
</template>

<script>
export default {
  name: "LoadingView",
};
</script>

<style scoped>
/* Loading Page Start */

/* Main container */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #eaf7fc, #ffffff); /* Cool gradient */
  color: #3a3a3a; /* Subtle text color */
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 20px;
}

/* Spinner container */
.spinner {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* Spinner animation */
.spinner-inner {
  width: 60px;
  height: 60px;
  border: 6px solid transparent;
  border-top: 6px solid #47aee8; /* Highlighted color */
  border-right: 6px solid #47aee8;
  border-radius: 50%;
  animation: spin 1.2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

/* Text styles */
h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  color: #3a87c6; /* Vibrant text color */
}

p {
  font-size: 16px;
  color: #5a5a5a;
  margin-top: 8px;
  opacity: 0.9;
}

/* Keyframe animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Loading Page End */
</style>
