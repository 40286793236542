<template>
  <loading-view v-if="loadingState" />
  <div v-else>
    <header-component />
    <router-view />
    <contact-buttons />
    <footer-component />
  </div>
</template>
<script>
import ContactButtons from "./components/buttons/ContactButtons.vue";
import HeaderComponent from "./components/HeaderComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import LoadingView from "./views/LoadingView.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderComponent,
    ContactButtons,
    FooterComponent,
    LoadingView,
  },
  computed: {
    ...mapGetters(["loadingState"]),
  },
};
</script>
<style lang="scss">
@use "./sass/base/typography" as *;
@use "./sass/base/reset" as *;
</style>
