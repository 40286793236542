<template>
  <footer class="footer">
    <div class="container">
      <!-- Contact Info -->
      <div class="footer-section contact-info">
        <h4>Contact Us</h4>
        <ul>
          <li>
            Email:
            <a
              href="mailto:admin@emails.miopiazza.com?subject=Inquiry%20about%20services"
              >islamtaha1997@gmail.com</a
            >
          </li>
          <li>Phone: <a href="tel:+201025247890">+201025247890</a></li>
          <li>
            Address:
            <span>123 Academy St., Knowledge City</span>
          </li>
          <li class="free-trial-btn">
            <a href="#join">Book A Free Lesson Now</a>
          </li>
        </ul>
      </div>

      <!-- Quick Links -->
      <div class="footer-section quick-links">
        <h4>Quick Links</h4>
        <ul>
          <li><router-link @click="scrollTop" to="/">Home</router-link></li>
          <li>
            <router-link @click="scrollTop" to="/about">About Us</router-link>
          </li>
          <li>
            <router-link @click="scrollTop" to="/plans">Plans</router-link>
          </li>
          <li>
            <router-link @click="scrollTop" to="/contact-us"
              >Contact Us</router-link
            >
          </li>
          <li>
            <router-link to="/terms_and_conditions"
              >Terms & Conditions</router-link
            >
          </li>
          <li>
            <router-link to="/privacy_policy">Privacy Policy</router-link>
          </li>
        </ul>
      </div>

      <!-- Social Media -->
      <div class="footer-section social-media">
        <h4>Follow Us</h4>
        <ul>
          <li>
            <a href="#"
              ><font-awesome-icon :icon="['fab', 'facebook-f']" /> Facebook</a
            >
          </li>
          <li>
            <a href="#"
              ><font-awesome-icon :icon="['fab', 'x-twitter']" />Twitter</a
            >
          </li>
          <li>
            <a href="#"
              ><font-awesome-icon :icon="['fab', 'instagram']" />Instagram</a
            >
          </li>
          <li>
            <a href="#"
              ><font-awesome-icon :icon="['fab', 'linkedin-in']" />LinkedIn</a
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="footer-bottom">
      <p>© 2024 <strong>Al-Furqan Academy</strong>. All rights reserved.</p>
    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    scrollTop() {
      window.scrollTo({ top: 0 });
    },
  },
};
</script>
<style lang="scss" scoped>
/* General Styles */
.footer {
  position: relative;
  background-color: #222;
  color: #fff;
  padding: 40px 20px;
  font-size: 0.9rem;
}
.footer .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.footer .footer-section {
  margin-bottom: 20px;
}
.footer-section h4 {
  margin-bottom: 15px;
  color: #f4c531; /* Accent color */
  font-size: 1.2rem;
}

.footer-section li {
  margin-bottom: 10px;
}
.footer-section a {
  color: #ddd;
  text-decoration: none;
}
.footer-section a:hover {
  color: #fff;
}

/* Social Media Icons */

.social-media a {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  margin-top: 20px;
  border-top: 1px solid var(--divider);
  padding-top: 10px;
  font-size: 0.85rem;
}
// free trial button
.free-trial-btn {
  a {
    background-color: var(--link);
    margin-top: 1em;
    padding: 0.5em 1em;
    display: block;
    width: fit-content;
    border-radius: 5px;
    font-size: 1.1em;
  }
}

/* Responsive Design */
// @media (max-width: 768px) {
// }
</style>
