export default {
  // Called when the directive is bound to the element
  beforeMount(el, binding) {
    el.clickOutsideEvent = function (event) {
      // Check if the click was outside the element
      if (!(el === event.target || el.contains(event.target))) {
        // If it was outside, call the method passed to the directive
        binding.value(event);
      }
    };
    // Attach event listener to the document
    document.addEventListener("click", el.clickOutsideEvent);
  },
  // Remove the event listener when the directive is unbound
  unmounted(el) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
