import { createStore } from "vuex";
import courses from "./modules/courses";

export default createStore({
  state: {
    isLoading: true,
  },
  mutations: {
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    showLoading({ commit }) {
      commit("setLoading", true);
    },
    hideLoading({ commit }) {
      commit("setLoading", false);
    },
  },
  getters: {
    loadingState: (state) => state.isLoading,
  },
  modules: { courses },
});
