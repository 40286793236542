import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import fadeInDirective from "./directives/fadeInDirective";
import slideInDirective from "./directives/slideInDirective";

// Import Font Awesome core and brand icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faWhatsapp,
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import clickOutsideDirective from "./directives/clickOutsideDirective";

library.add(
  faWhatsapp,
  faEnvelope,
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedinIn
);

const app = createApp(App);
app.directive("fade-in", fadeInDirective);
app.directive("slide-in", slideInDirective);
app.directive("click-outside", clickOutsideDirective);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store).use(router).mount("#app");
